import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import ActivityExample from "../shared/program/ActivityExample"
import HeadingM from "../shared/HeadingM"

const query = graphql`
  query {
    yoga: file(relativePath: { eq: "nico/activity/sports.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500, height: 500)
      }
    }
    enjoy: file(relativePath: { eq: "nico/activity/enjoy.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500, height: 500)
      }
    }
    work: file(relativePath: { eq: "nico/activity/work.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500, height: 500)
      }
    }
  }
`

const ProgramActivityExample = () => {
  const data = useStaticQuery(query)
  const items = [
    {
      title: "nicoスポーツ",
      description:
        "バスケットボール、バトミントン、ランニング etc.\n日頃から体幹を鍛えるトレーニングを取り入れた活動！",
      image: getImage(data.yoga),
    },
    {
      title: "nicoワーク",
      description:
        "働くことの楽しさ、大切さを自然と身につけ、一人ひとりのこれからの生きる力をサポートできるように、同法人が運営するDo CAFEでお仕事を！（caféでお手伝いをさせていただきます。）",
      image: getImage(data.work),
    },
    {
      title: "nicoエンジョイ",
      description:
        "楽しいから始められる。楽しいから見つけられる。楽しいから続けられる。そんな居場所を一緒に作りましょう。みんなで楽しいを！",
      image: getImage(data.enjoy),
    },
  ]
  return (
    <div>
      <HeadingM title="活動事例" target="nico" />
      <ActivityExample items={items} />
    </div>
  )
}

export default ProgramActivityExample
